import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from './assets/images/logo.png';
//import Snowflakes from './Snowflakes'; -- для активации надо будет вставить "<Snowflakes />" между "<HeaderContainer>" и "<LogoContainer to="/">" - 127 и 128 строки + разкомментить строку в App.js

const HeaderContainer = styled.header`
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 768px) {
    padding: 10px 0;
    justify-content: space-between;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin-left: 10px;
  }
`;

const Logo = styled.img`
  width: 247px;
  height: 68px;

  @media (max-width: 768px) {
    width: 180px;
    height: 50px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: ${({ open }) => (open ? '0' : '-100%')};
    width: 100%;
    background: #1a1a1a;
    transition: left 0.3s ease;
    z-index: 1;
  }
`;

const NavLink = styled(Link)`
  margin: 0 10px;
  font-size: 18px;
  font-weight: 600;
  color: #bebebe;
  text-decoration: none;
  position: relative;

  &:hover {
    color: #fff;
    text-shadow: 0 2px 10px white;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: #ea864d;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover:after {
    opacity: 1;
    box-shadow: 0 0 15px #ea864d;
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    font-size: 16px;
  }
`;

const MenuIcon = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
  }
`;

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <Logo src={logo} alt="PREMIER GAME" />
      </LogoContainer>
      <MenuIcon onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>
      <Nav open={menuOpen}>
        <NavLink to="https://forum.premier-game.ru" onClick={closeMenu}>Форум</NavLink>
        <NavLink to="/faq" onClick={closeMenu}>Помощь</NavLink>
        <NavLink to="/shop" onClick={closeMenu}>Магазин</NavLink>
        <NavLink to="/forgot-password" onClick={closeMenu}>Забыл пароль</NavLink>
        <NavLink to="/statistics" onClick={closeMenu}>Статистика</NavLink>
        <NavLink to="/dashboard" onClick={closeMenu}>Личный кабинет</NavLink>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;